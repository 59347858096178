import {
  ICommitBudgetRequest,
  ICommitDraftBudgetResponse,
  IProspectAssetResponse,
  IProspectDataImage,
  IProspectSettingsRequest,
  ISetBudgetRequest,
} from "api/prospect"
import { get, put, post } from "api/restClient"
import { IProspectCampaignRequest, IProspectViewModel, ITargeting } from "entities/interfaces"
import { PhoneNumberSetting, ReferenceType, TemplateChannel } from "../../entities/enums"

export interface IOrdered {
  userId: string
  timestamp: string
  userName: string
  userEmail: string
  orderNumber: number
  reference: string | null
}

export interface IDraftListModel {
  id: string
  name: string
  createdAt: string
  opened: number
  userEmails: string[]
  amount: number | null
  ordered: IOrdered | null
  clientName?: string
  groupName: string
  clientOrHqSlug?: string
  status?: string
}

export interface IDraftClient {
  clientIds: string[]
}

export interface IDraft {
  clientId?: string
  facebookPageId: string
  magicToken?: string
  campaignName: string
  fromDraftTemplate?: boolean
  locations?: [
    {
      name: string
      radius?: number
    }
  ]
  coordinates?: [
    {
      latitude: number
      longitude: number
      radius?: number
    }
  ]
  referenceSettings?: ReferenceType
  phoneNumberSetting?: PhoneNumberSetting
  pricingLevels: string[]
  initialPrice?: number
  targeting?: {
    gender: number
    minAge: number
    maxAge: number
  }
  channelShares: Record<TemplateChannel, number>
  campaignData: {
    landingPage?: string
    header?: string
    phoneNumber?: string
    itemDefaults?: {
      title?: string
      subTitle?: string
      phoneNumber?: string
    }
    items?: {
      title: string
      subTitle?: string
      imageUrl: string
      landingPage?: string
      templateData?: Record<string, string>
      phoneNumber?: string
    }[]
  }
  templateTags: string[]
  reportRecipients?: string[]
  imageCategories?: string[]
  startUtc?: Date
  endUtc: Date
  landingPageSetting?: "uneditable" | "unique" | "same"
  lockGeoTargets: boolean
}

export interface IDraftRes {
  draftId: string
  token: string
  refreshToken: string
  clientSlug: string
}

export interface ISendDraftRes {
  drafts : IDraftClientArr[]
}

interface IDraftClientArr {
  draftId : string
  clientId : string
}

export const getById = (draftId: string) => get<IProspectCampaignRequest & IProspectViewModel>(`Draft/${draftId}`)

export const getListByClientId = (clientId: string) => get<IDraftListModel[]>(`/Draft/client/${clientId}`)

export const getListByUserId = (userId: string) => get<IDraftListModel[]>(`/Draft/user/${userId}`)

export const getListByTenantId = (id: string) => get<IDraftListModel[]>(`/Draft/tenant/${id}`)

export const updateTargeting = (id: string, data: ITargeting) => put(`/Draft/${id}/targeting`, data)

export const updateSettings = (id: string, data: IProspectSettingsRequest) => put(`/Draft/${id}/settings`, data)

export const updateImages = (id: string, data: IProspectDataImage[]) => put(`/Draft/${id}/images`, data)

export const updateReportRecipients = (id: string, data: string[]) => put(`/Draft/${id}/reportRecipients`, data)

export const updateBudget = (id: string, data: ISetBudgetRequest) => put(`/Draft/${id}/budget`, data)

export const updateCampaignManagers = (id: string, data: string[]) => put(`/Draft/${id}/campaignManagers`, data)

export const commitBudget = (id: string, data: ICommitBudgetRequest) =>
  post<ICommitDraftBudgetResponse>(`/Draft/${id}/commit`, data)

export const getRecommendedTags = (draftId: string) => get<{ tags: string[] }>(`/Draft/${draftId}/tags`)

export const uploadImage = (id: string, file: FormData) =>
  post<IProspectAssetResponse>(`/Draft/assets/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const create = (data: IDraft) => post<IDraftRes>(`/Draft`, data)

export const sendDraftEmails = (id: string, clientIds: IDraftClient) => post<ISendDraftRes>(`/OrderTemplate/${id}/send-drafts`, clientIds)
