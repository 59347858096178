import { ILocation } from "entities/interfaces"
import { geocodeByPlaceId } from "react-places-autocomplete"
import { retryFn } from "./promiseHelpers"

export const RADIUS_PER_GEOGRAPHIC_TYPES = {
  street_address: 15,
  neighborhood: 15,
  premise: 15,
  subpremise: 15,
  airport: 30,
  locality: 30,
  park: 15,
  point_of_interest: 15,
  route: 15,
  floor: 15,
  street_number: 15,
}

const shouldNotValidateGeo = (locations: google.maps.GeocoderResult[], outerIndex: number, innerIndex: number): boolean => {
  const countryOrCounty = ["country", "administrative_area_level_1", "administrative_area_level_2"]
  if (countryOrCounty.includes(locations[outerIndex].types[0]) && countryOrCounty.includes(locations[innerIndex].types[0])) {
    return false
  }
  const addresGeoTypes = ["premise", "street_address", "route"]
  const allTypesAreAdresses = locations.every((l) => addresGeoTypes.includes(l.types[0]))
  return allTypesAreAdresses
}

export const validateLocationsOverlap = async (locations: ILocation[]) => {
  if (!locations.length) return false

  const promises = locations.map((l) =>
    retryFn(
      async () => {
        const result = await geocodeByPlaceId(l.data.place_id)
        return result[0]
      },
      1000,
      20
    )
  )
  const tempLocations: google.maps.GeocoderResult[] = await Promise.all(promises)
  if (!tempLocations.length) return false

  for (let i = 0; i < tempLocations.length; i++) {
    const outer = tempLocations[i].geometry
    for (let j = 0; j < tempLocations.length; j++) {
      if (i === j) continue
      const inner = tempLocations[j].geometry

      if (shouldNotValidateGeo(tempLocations, i, j)) continue

      if (outer.viewport.contains(inner.location)) return false
    }
  }
  return true
}
